html,
body,
#root {
  height: 100%;
}
#root > div {
  height: inherit;
}

#rooter span {
  font-family: Poppins;
}

label {
  font-family: Poppins !important;
  font-style: normal !important;
  font-size: 12px !important;
  line-height: 13px !important;
  margin-bottom: 10px !important;
  letter-spacing: 0.02em !important;
  color: #5d6e82 !important;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.btn {
  border-radius: 0px !important;
  font-size: 13px !important;
  height: 36px !important;
}

.btn-primary {
  background: #007aff !important;
  border: 1px solid #d8e2ee !important;
  box-sizing: border-box !important;
}

.inline-field {
  display: inline-block !important;
  margin-left: 20px !important;
  width: 25% !important;
  border: none !important;
  border-bottom: 1px solid #d8e2ee !important;
  border-radius: 0% !important;
  height: 20px !important;
}

.inline-selectfield {
  display: inline-block !important;
  margin-left: 20px !important;
  width: 70% !important;
}

.custom-control-label {
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 11px !important;
  line-height: 16px !important;
  letter-spacing: 0.03em !important;
  color: #515e6f !important;
}

.custom-control-label::before {
  top: 0 !important;
}

.custom-control-label::after {
  top: 0 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.invalid-feedback {
  display: block !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-24 {
  font-size: 24px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-7 {
  margin-top: 7px !important;
}

.m-t-17 {
  margin-top: 17px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-40 {
  padding: 40px !important;
}

.pointer {
  cursor: pointer;
}

.company-logo {
  margin: auto;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-init-cap {
  text-transform: capitalize;
}

/* .role-tag {
  font-size: 12px;
} */

.Simple {
  margin-top: 10px;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  /* width: 294px; */
  height: 100px;
  padding: 5px;
}

/* Example Styles for React Tags*/

#app {
  padding: 40px;
}

/* css  */

@media only screen and (max-width: 550px) {
  .theme-wizard .nav-link .nav-item-circle {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .nav-item-circle .svg-inline--fa {
    height: 10px;
    vertical-align: 9px;
  }
  .wizard-label {
    font-size: 9px !important;
  }
}

.MuiPaper-root {
  background-color: transparent !important;
}

.auth-card-logo {
  width: 200px;
}

/* Select */

.select__placeholder {
  top: 48% !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  display: flex;
  align-items: center;
  margin-left: 7px !important;
  color: #b6c2d2 !important;
}

.select__control {
  height: 36px;
  min-height: 36px !important;
  box-sizing: border-box !important;
  border-radius: 0px !important;
}

.invalid .select__control {
  border-color: #e63757 !important;
}

.valid .select__control {
  min-height: 39px !important;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-style: solid;
  border-width: 1px;
  border: 1px solid #cccccc !important;
}

.event-state-container .valid .select__control {
  min-height: 39px !important;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-style: solid;
  border-width: 1px;
  border: 1px solid #cccccc !important;
}

.event-state-container .btn-group > .btn {
  min-height: 39px !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.player-wrap {
  height: 100% !important;
}

.download-button {
  width: 200px !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  margin: 0px auto;
  color: #007aff !important;
  background-color: transparent !important;
  border: 1px solid #007aff !important;
  border-radius: 3px !important;
}

.dropdown-menu,
a {
  cursor: pointer;
}

@media only screen and (max-width: 760px) {
  .inline-field {
    height: 36px !important;
  }
  .player-wrap {
    height: auto !important;
  }
  .tags_fields {
    margin-bottom: 11px;
  }
  .tag_heading {
    padding-left: 7px !important;
  }
}

@media only screen and (max-width: 1920px) and (min-width: 1681px) {
  body {
    zoom: 1 !important;
    /* Other non-webkit browsers */
    zoom: 100% !important;
    /* Webkit browsers */
  }
  .modal {
    zoom: 100% !important;
  }
  .modal-backdrop {
    zoom: 100% !important;
  }
}

@media only screen and (max-width: 1680px) and (min-width: 1537px) {
  body {
    zoom: 0.92 !important;
    /* Other non-webkit browsers */
    zoom: 92% !important;
    /* Webkit browsers */
  }
  .modal {
    zoom: 109% !important;
  }
  .modal-backdrop {
    zoom: 109% !important;
  }
}

@media only screen and (max-width: 1536px) and (min-width: 1441px) {
  body {
    zoom: 0.7 !important;
    /* Other non-webkit browsers */
    zoom: 70% !important;
    /* Webkit browsers */
  }
  .modal {
    zoom: 131% !important;
  }
  .modal-backdrop {
    zoom: 131% !important;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
  body {
    zoom: 0.7 !important;
    /* Other non-webkit browsers */
    zoom: 70% !important;
    /* Webkit browsers */
  }
  .modal {
    zoom: 143% !important;
  }
  .modal-backdrop {
    zoom: 143% !important;
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  body {
    zoom: 0.68 !important;
    /* Other non-webkit browsers */
    zoom: 68% !important;
    /* Webkit browsers */
  }
  .modal {
    zoom: 148% !important;
  }
  .modal-backdrop {
    zoom: 148% !important;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 768px) {
  body {
    zoom: 0.67 !important;
    /* Other non-webkit browsers */
    zoom: 67% !important;
    /* Webkit browsers */
  }
  .modal {
    zoom: 150% !important;
  }
  .modal-backdrop {
    zoom: 150% !important;
  }
}

/* TextArea */
.customTextarea textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none !important; /*remove the resize handle on the bottom right*/
}

.customTextarea .form-control {
  border: none;
}

.mx-with-125 {
  max-width: 125px !important;
}
