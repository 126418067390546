body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0px;
    padding: 0px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.sticky-sidebar {
    top: 72px !important;
}


/* sidepanel start*/

.sidebar-modal .modal-dialog {
    max-width: 60% !important;
}

.sidebar-modal .modal-header-settings .close {
    top: 12px;
}

.heading-wrap {
    color: #344050;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
}

.heading-02 {
    color: #344050;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 25px;
    font-size: 13px;
    line-height: 13px;
}

.sidepanel-info-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #344050;
}

.sidepanel-value {
    color: #5d6e82;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
}

@media only screen and (max-width:468px) {
    .sidebar-modal .modal-dialog {
        max-width: 100% !important;
    }
    .progress-circle {
        display: flex;
        justify-content: center;
    }
}


/* side panel end */


/* date and time start*/

.rdtPicker {
    width: 100% !important;
}

.rdtPicker td.rdtDay:hover {
    border-radius: 4px !important;
    color: #fff !important;
    background-color: #000000 !important;
}

.rdtPicker td.rdtDay {
    border-radius: 4px !important;
}


/* date and time end*/


/* modal */

.role-modal {
    width: 50vw;
    max-width: 50vw;
}

.company-modal {
    width: 70vw !important;
    max-width: 70vw !important;
}

@media only screen and (max-width:768px) {
    .role-modal {
        width: 94vw !important;
        max-width: 94vw !important;
    }
    .company-modal {
        width: 94vw !important;
        max-width: 94vw !important;
    }
}


/* end */


/* table  */

.table tr th:last-child {
    padding-right: 1.25rem;
}

td.status-col {
    width: 9%;
}


/* table */


/* dropdown start*/

.dropdown-menu.dropdown-menu-right.show button {
    display: inline-block;
    float: right;
    height: 25px;
    z-index: 1024;
    position: relative;
}


/* dropdown end */


/* profile dropdown start*/

.dropdown-menu-card.profile-dropdown.dropdown-menu.dropdown-menu-right.show {
    top: 50px;
    padding: 0px 0px 12px 0px;
}


/* profile dropdown end*/

.navbar-vertical-collapsed .nav-icon svg.svg-inline--fa.fa-building.fa-w-14 {
    height: 1em !important;
    width: 1.25em !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    margin-top: 2px !important;
}

.custom-switch .custom-control-input ~ .custom-control-label::after {
    margin-top: 2px !important;
}
